import { Link } from 'react-router-dom'

import { DeleteOutlined, HistoryOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { Image, Button, Divider, Modal, Badge } from 'antd'

import { BgStatusTags, Product, canDeleteProduct, formatProductType, hasHeroImage } from '../..'
import * as S from './styles'

import anymarketLogo from '@/assets/logo-anymarket.svg'
import vtexLogo from '@/assets/logo-vtex-single.svg'
import { formatCurrency, formatDate, imgFallback, RolePermissions, truncateString } from '@/common'
import { TableAction } from '@/components'
import { SelectProductActions } from '@/pages'

type BgCardProps = {
  data: Product
  isLoading?: boolean
  productPermissions: RolePermissions
  handleSelectProduct: ({ product, type }: SelectProductActions) => void
}

export const BgCard = ({ data, handleSelectProduct, productPermissions }: BgCardProps) => {
  const navigateRoute = data?.sku?.is_kit
    ? `/product/kit/${data.id}`
    : data?.status === 'EM RASCUNHO DO PRODUTO'
    ? `/create-product/${data.id}`
    : `/product/${data.id}`

  const dropdownOptions = [
    {
      key: '1',
      label: (
        <S.ButtonContent>
          <Button
            icon={<HistoryOutlined />}
            type="link"
            onClick={(e) => {
              e.preventDefault()
              // e.stopPropagation()

              handleSelectProduct({ product: data, type: 'VIEW_HISTORY' })
            }}
          >
            Ver histórico
          </Button>
        </S.ButtonContent>
      ),
    },
    (productPermissions?.isAdmin || productPermissions?.canDelete) && canDeleteProduct(data.status)
      ? {
          key: '2',
          label: (
            <S.ButtonContent>
              <Button
                danger
                type="link"
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  e.preventDefault()
                  // e.stopPropagation()

                  confirmDeletion(data)
                }}
                disabled={!canDeleteProduct(data.status)}
              >
                Excluir
              </Button>
            </S.ButtonContent>
          ),
        }
      : null,
  ]

  function confirmDeletion(product: Product) {
    Modal.confirm({
      title: `Deseja realmente excluir este produto?`,
      content: (
        <span>
          O produto <strong>{truncateString(product.name || product.model, 90)}</strong> será
          excluido permanentemente.
        </span>
      ),
      centered: true,
      icon: <ExclamationCircleFilled style={{ color: '#FF0000' }} />,
      okText: 'Excluir',
      cancelText: 'Cancelar',
      footer: (_, { OkBtn, CancelBtn }) => (
        <S.ModalFooter>
          <CancelBtn />
          <OkBtn />
        </S.ModalFooter>
      ),
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          background: '#80c343',
        },
      },
      cancelButtonProps: {
        danger: true,
      },
      onOk() {
        handleSelectProduct({ product, type: 'DELETE_PRODUCT' })
      },
    })
  }

  return (
    <Link to={navigateRoute}>
      <S.Container>
        <S.BadgesContainer>
          {data?.sku?.is_kit ? <Badge.Ribbon color="#1E9493" text="Kit" placement="start" /> : null}

          {data?.is_b2b ? <Badge.Ribbon color="#4A91FD" text="B2B" placement="start" /> : null}

          {data?.vtex_legacy ? (
            <Badge.Ribbon color="#FF3366" text="Importado VTEX" placement="start" />
          ) : null}
        </S.BadgesContainer>

        <S.ImageContainer>
          <Image
            height={152}
            width={174}
            preview={false}
            fallback={imgFallback}
            alt={`Hero image de ${data.name}`}
            src={
              hasHeroImage(data?.status)
                ? data?.partner_files?.hero_image_partner_url
                : data?.sku?.sku_files?.[0]?.filename_url
            }
          />
        </S.ImageContainer>

        <TableAction
          isCard
          trigger={['hover']}
          icon={<S.DropdownIcon />}
          dropdownOptions={dropdownOptions}
        />

        <S.ProductCategory>
          <span>{formatProductType(data.product_type_name) || '--'}</span>

          <Divider type="vertical" style={{ margin: 0 }} />

          <strong>{truncateString(data.category_name, 20) || '--'}</strong>
        </S.ProductCategory>

        <S.ProductFamily>{truncateString(data?.family, 28) || '--'}</S.ProductFamily>

        <S.BGName>{data.name ? truncateString(data.name, 90) : data.model}</S.BGName>

        <Divider style={{ margin: 0 }} />

        <S.InfoContainer>
          <S.ProductPrice $hasMarketplacePrice={!!data?.sku?.price || false}>
            <Image src={vtexLogo} width={24} height={24} alt="Logo VTEX" preview={false} />

            {formatCurrency({ value: data?.sku?.price, type: 'currency' }) || '--'}
          </S.ProductPrice>

          <S.ProductPrice $hasMarketplacePrice={!!data?.sku?.price_anymarket || false}>
            <Image
              src={anymarketLogo}
              width={24}
              height={24}
              alt="Logo AnyMarket"
              preview={false}
            />

            {formatCurrency({
              value: data?.sku?.price_anymarket as number | string,
              type: 'currency',
            }) || '--'}
          </S.ProductPrice>
        </S.InfoContainer>

        <S.Row>
          <BgStatusTags status={data.status} />
          <S.DateContainer>
            <span>Atualizado em</span>
            <S.Date>{formatDate({ date: data.updated_at, dateFormat: 'DD/MM/YYYY' })}</S.Date>
          </S.DateContainer>
        </S.Row>
      </S.Container>
    </Link>
  )
}
